export const API_URL = "https://api.megadom.space/v1";
export const FILEMANAGER_URL = "https://api.megadom.space/fm";
export const UPLOADS_URL = "https://api.megadom.space/public/uploads";
export const CHAT_URL = "http://188.68.223.96:3003";
export const PBX_URL = "http://78.29.44.162";

// export const API_URL = "http://localhost:3001/v1";
// export const FILEMANAGER_URL = "http://localhost:3001/fm";
// export const UPLOADS_URL = "http://localhost:3001/public/uploads";
// export const CHAT_URL = "http://localhost:3003";
// export const PBX_URL = "http://78.29.44.162";

export const events = [
  {
    section: "objects",
    choices: [
      { id: "onObjectPrimaryTransferredTo", name: "Передача объекта агенту(Первичка)" },
      { id: "onObjectResaleTransferredTo", name: "Передача объекта агенту(Вторичка)" },
      { id: "onObjectCommercialTransferredTo", name: "Передача объекта агенту(Коммерческая)" },
      { id: "onObjectRentTransferredTo", name: "Передача объекта агенту(Аренда)" },
      { id: "onObjectCountrysideTransferredTo", name: "Передача объекта агенту(Загородная)" },
      { id: "onObjectOtherTransferredTo", name: "Передача объекта агенту(Прочая)" },
      { id: "onObjectArchived", name: "Объект заархивирован" },
      { id: "onObjectCreated", name: "Объект создан" },
      { id: "onObjectUpdated", name: "Объект обновлен" },
      { id: "onObjectSoldOut", name: "Объект продан" },
      { id: "onObjectStatusConfirmed", name: "Статус объекта изменен" },
    ]
  },
  {
    section: "clients",
    choices: [
      { id: "onClientCreated", name: "Клиент создан" },
      { id: "onClientCreatedOther", name: "Клиент создан(Ипотека)" },
      { id: "onClientUpdated", name: "Клиент обновлен" },
      { id: "onClientRemoved", name: "Клиент удален" },
      { id: "onClientSetObject", name: "Клиенту добавлен объект" },
      { id: "onClientRemovedObject", name: "Удален объект у клиента" },
    ]
  },
  {
    section: "pbx",
    choices: [
      { id: "onPBXCreated", name: "Звонок создан" },
      { id: "onPBXUpdated", name: "Звонок обновлен" },
      { id: "onPBXBusy", name: "Занято" },
      { id: "onPBXEnd", name: "Разговор закончен" },
      { id: "onPBXDialing", name: "Разговор идет" },
    ]
  },
  {
    section: "tasks",
    choices: [
      { id: "onTaskCreated", name: "Задача создана" },
      { id: "onTaskUpdated", name: "Задача обновлена" }
    ]
  },
  {
    section: "robots",
    choices: [
      { id: "onRobotUp", name: "Робот подключен" },
      { id: "onRobotUpdated", name: "Робот обновлен" }
    ]
  },
]; 

export const actions = {
  clients: [
    { id: 'CREATE_TASK_CLIENTS', name: 'Создать задачу' },
    { id: 'sendEmail', name: 'Отправить почту' }
  ],
  objects: [
    { id: 'CREATE_TASK_OBJECTS', name: 'Создать задачу' },
    { id: 'sendEmail', name: 'Отправить почту' }
  ],
  pbx: [
    { id: 'CREATE_TASK_PBX', name: 'Создать задачу' },
    { id: 'sendEmail', name: 'Отправить почту' }
  ],
  tasks: [
    { id: 'CREATE_TASK_TASKS', name: 'Создать задачу' },
    { id: 'sendEmail', name: 'Отправить почту' }
  ],
  robots: [
    { id: 'CREATE_TASK_ROBOTS', name: 'Создать задачу' },
    { id: 'sendEmail', name: 'Отправить почту' }
  ],
};

export const sections = [
  { id: "objects", name: "Объекты" },
  { id: "clients", name: "Клиенты" },
  { id: "pbx", name: "Телефония" },
  { id: "tasks", name: "Задачи" },
  { id: "robots", name: "Роботы" },
];

export const socket = {
  instance: null
};